import React from 'react'
import clsx from "clsx"
import Review from './Review'
import Review2 from './Review2'
import Review3 from './Review3'
import reviewHeader from "../img/urumane_nexthead_01.png"
import styles from "./reviewlist.module.scss"

const ReviewList = () => {
    return (
      <section className={clsx("section", styles.reviewList)}>
        <div className="container">
          <img src={reviewHeader} alt="買取サイトレビュー" className={styles.header} />
          <Review />
          <Review2 />
          <Review3 />
        </div>
      </section>
    )
}

export default ReviewList