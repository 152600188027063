import React from 'react'
import clsx from "clsx"
import banner from '../img/kaitori_dx_400x276_02.png'
import styles from "./review.module.scss"

const Review2 = () => {
    return (
      <div className={clsx("content", styles.review)}>
        <div className="columns is-vcentered is-desktop">
          <div className="column is-one-third has-text-centered">
            <a
              href="https://kaitori-dx.com?af=um0927"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={banner} alt="買取デラックス" />
            </a>
          </div>
          <div className="column">
            <div className="title is-4">Amazonギフト券の高価買取なら買取率93%の【買取デラックス】</div>
            <p>
2019年11月にオープンした買取ボブの姉妹店です。
            </p>
            <p>
運営会社が買取ボブと同じため大きな安心感があります。
            </p>
            <p>
買取ボブと比較して「振込速度がさらに早い」「買取可能な券種が多い」等の特徴があります。
            </p>
            <p>
初回申込は買取率が通常よりも高くてお得です。
            </p>
          </div>
        </div>
        <dl className="table">
          <dt>換金率</dt>
          <dd>
最大&nbsp;
            <span className="is-size-5 has-text-danger">91</span>
&nbsp;
            <span className="is-size-5">%</span>
          </dd>
          <dt>振込手数料</dt>
          <dd>
            <span className="is-size-5 has-text-danger">0</span>
&nbsp;円
          </dd>
          <dt>買取スピード</dt>
          <dd>
最短&nbsp;
            <span className="is-size-5 has-text-danger">5</span>
&nbsp;分
          </dd>
          <dt>スタッフの対応</dt>
          <dd><span className="is-size-5 has-text-danger">◎</span></dd>
          <dt>その他の特徴</dt>
          <dd>
365日24時間営業 / 電子ギフト券9種類買取 / 古物営業許可店
          </dd>
        </dl>
        <div className="has-text-centered">
          <a href={`${process.env.GATSBY_REPLACEMENT_URL}/kaitori-dx`} className="button is-success is-large is-halfwidth">レビューページへ</a>
        </div>
      </div>
    )
}

export default Review2;