import React from 'react'
import clsx from "clsx"
import banner from '../img/banner_400×276_03.png'
import styles from "./review.module.scss"

const Review = () => {
    return (
      <div className={clsx("content", styles.review)}>
        <div className="columns is-vcentered is-desktop">
          <div className="column is-one-third has-text-centered">
            <a
              href="https://kaitoribob.com/?af=um0917"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={banner} alt="買取ボブ" />
            </a>
          </div>
          <div className="column">
            <div className="title is-4">amazonギフト券買取 老舗の古物営業許可店 24H営業【買取ボブ】</div>
            <p>
Amazon・iTunes・GooglePlayギフト券の買取りは業界トップ買取率の買取ボブへ
            </p>
            <p>
振込手数料その他手数料一切なし・年中無休24H営業・PC・スマホから簡単買取・土日祭日深夜も対応！
老舗の古物営業許可店、最短10分でお振込みいたします。
            </p>
            <p>
スタッフの対応も丁寧なので初心者の方も安心して申し込むことができます。
            </p>
          </div>
        </div>
        <dl className="table">
          <dt>換金率</dt>
          <dd>
最大&nbsp;
            <span className="is-size-5 has-text-danger">93</span>
&nbsp;
            <span className="is-size-5">%</span>
          </dd>
          <dt>振込手数料</dt>
          <dd>
            <span className="is-size-5 has-text-danger">0</span>
&nbsp;円
          </dd>
          <dt>買取スピード</dt>
          <dd>
最短&nbsp;
            <span className="is-size-5 has-text-danger">10</span>
&nbsp;分
          </dd>
          <dt>スタッフの対応</dt>
          <dd><span className="is-size-5 has-text-danger">◎</span></dd>
          <dt>その他の特徴</dt>
          <dd>
365日24時間営業 / キャンペーンで買取率UP / 古物営業許可店
          </dd>
        </dl>
        <div className="has-text-centered">
          <a href={`${process.env.GATSBY_REPLACEMENT_URL}/kaitori-bob`} className="button is-success is-large is-halfwidth">レビューページへ</a>
        </div>
      </div>
    )
}
    
export default Review;