import React from 'react'
import clsx from "clsx"
import banner from '../img/gift-grace-400x300.png'
import styles from "./review.module.scss"

const Review3 = () => {
    return (
      <div className={clsx("content", styles.review)}>
        <div className="columns is-vcentered is-desktop">
          <div className="column is-one-third has-text-centered">
            <a
              href="https://track.bannerbridge.net/click.php?APID=141599&affID=98093&siteID=189672"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.giftGraceBanner} src={banner} alt="ギフトグレース" />
            </a>
          </div>
          <div className="column">
            <div className="title is-4">あなたのアマゾンギフト高く買います【ギフトグレース】</div>
            <p>
買取ボブと並ぶ電子ギフト券買取の老舗がギフトグレースです。
            </p>
            <p>
額面500円から買取してもらうことが可能です。
            </p>
            <p>
お急ぎの場合は「特急プラン」で最短振込、
時間に余裕がある場合は「翌日プラン」で高額買取。
            </p>
            <p>
ネット上でも良い評価が多い優良サイトです。
            </p>
          </div>
        </div>
        <dl className="table">
          <dt>換金率</dt>
          <dd>
最大&nbsp;
            <span className="is-size-5 has-text-danger">90</span>
&nbsp;
            <span className="is-size-5">%</span>
          </dd>
          <dt>振込手数料</dt>
          <dd>
            <span className="is-size-5 has-text-danger">0</span>
&nbsp;円　※ギフト券額面が9,999円以下の場合216円
          </dd>
          <dt>買取スピード</dt>
          <dd>
最短&nbsp;
            <span className="is-size-5 has-text-danger">10</span>
&nbsp;分
          </dd>
          <dt>スタッフの対応</dt>
          <dd><span className="is-size-5 has-text-danger">◎</span></dd>
          <dt>その他の特徴</dt>
          <dd>
電話サポートあり / キャンペーンで買取率UP / 古物営業許可店
          </dd>
        </dl>
      </div>
    )
}

export default Review3;