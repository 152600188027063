import React from 'react'
import reviewHeader from '../img/urumane_tophead_01.png'
import medalGold from '../img/medal-crown-2623-gold-400x400.png'
import medalSilver from '../img/medal-crown-2623-silver-400x400.png'
import medalBronze from '../img/medal-crown-2623-bronze-400x400.png'
import kaitoriBob from '../img/kaitori-bob-1-1024x768.png'
import kaitoriDx from '../img/kaitori_DX_01.png'
import giftGrace from '../img/gift-grace-1024x769.png'

const Ranking = () => {
    return (
      <>
        <div className="container">
          <img src={reviewHeader} alt="買取サイトレビュー" />
        </div>
        <div className="grid">
          <div className="cards__item rank2">
            <div className="card__rank">
              <img src={medalSilver} className="card__crown" alt="" />
            </div>
            <div className="card">
              <div className="card__image">
                <a
                  href="https://kaitori-dx.com?af=um0927"
                  target="_blank"
                  rel="noopener noreferrer"
                > 
                  <img src={kaitoriDx} style={{ "border": "1px solid rgb(0, 0, 0, .1)" }} alt="買取DX" />
                </a>
              </div>
              <div className="card__content">
                <div className="subtitle is-4">
                Amazonギフト券の高価買取なら買取率93％の【買取デラックス】
                </div>
                <div className="card__text">
                  <span className="tag is-info is-large">おすすめ度</span>
                  <div className="card__star--evaluation">
                    <span className="card__start--rate" style={{"--rate": "80%"}} />
                    <br />
                  </div>
                </div>
                <a
                  href="https://kaitori-dx.com?af=um0927"
                  target="_blank"
                  rel="noopener noreferrer"
                // className="btn btn--block card__btn"
                  className="button is-success is-large"
                >
公式サイトへ
                </a
                >
                <img
                  href="https://kaitori-dx.com?af=um0927"
                  width="1"
                  height="1"
                  border="0"
                />
              </div>
            </div>
          </div>  
          <div className="cards__item rank1">
            <div className="card__rank">
              <img src={medalGold} className="card__crown" alt="" />
            </div>
            <div className="card">
              <div className="card__image">
                <a
                  href="https://kaitoribob.com/?af=um0917"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={kaitoriBob} alt="買取ボブ" />
                </a>
              </div>
              <div className="card__content">
                <div className="subtitle is-4">
                amazonギフト券買取 老舗の古物営業許可店 24H営業【買取ボブ】
                </div>
                <div className="card__text">
                  <span className="tag is-info is-large">おすすめ度</span>
                  <div className="card__star--evaluation">
                    <span className="card__start--rate" style={{"--rate": "90%"}} />
                    <br />
                  </div>
                </div>
                <a
                  href="https://kaitoribob.com/?af=um0917"
                  target="_blank"
                  rel="noopener noreferrer"
                // className="btn btn--block card__btn"
                  className="button is-success is-large"
                >
公式サイトへ
                </a
                >
              </div>
            </div>
          </div>
          <div className="cards__item rank3">
            <div className="card__rank">
              <img src={medalBronze} className="card__crown" alt="" />
            </div>
            <div className="card">
              <div className="card__image">
                <a
                  href="https://track.bannerbridge.net/click.php?APID=141599&affID=98093&siteID=189672"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={giftGrace} style={{ "border": "1px solid rgb(0, 0, 0, .1)" }} alt="ギフトグレース" />
                </a>
              </div>
              <div className="card__content">
                <div className="subtitle is-4">
                あなたのアマゾンギフト高く買います【ギフトグレース】
                </div>
                <div className="card__text">
                  {/* <div className="card__star--heading">おすすめ度</div> */}
                  <span className="tag is-info is-large">おすすめ度</span>
                  <div className="card__star--evaluation">
                    <span className="card__start--rate" style={{"--rate": "70%"}} />
                    <br />
                  </div>
                </div>
                <a
                  href="https://track.bannerbridge.net/click.php?APID=141599&affID=98093&siteID=189672"
                  target="_blank"
                // className="btn btn--block card__btn"
                  className="button is-success is-large"
                  rel="noopener noreferrer"
                >
公式サイトへ
                </a
                >
                <img
                  border="0"
                  width="1"
                  height="1"
                  src="https://www18.a8.net/0.gif?a8mat=35O8FM+E8M8MQ+2TZ4+631SY"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Ranking;